import React, { useState } from 'react';
import styles from "./Category.module.scss";
import { Button, Modal } from 'antd';
import Container from "../../UI/Container";
import BlockInfoText from "../../UI/BlockInfoText";
import { motion } from 'framer-motion';
import { TrophyOutlined, VideoCameraOutlined, StarOutlined, TeamOutlined } from '@ant-design/icons';

const Category = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const categories = [                    
                    '1.	Best Short Film',
                    '2.	Best Animated Film',
                    '3.	Best Documentary Short Film',
                    '4.	Best Experimental Film',
                    '5.	Best Student Short Film',
                    '6.	Best Experimental Film',
                    '7.	Best Art House Film',
                    '8.	Best Avant-Garde Film',
                    '9.	Best Auteur Film',
                    '10.	Best Contemporary Intellectual Film',
                    '11.	Best Interactive Film',
                    '12.	Best Collage Film',
                    '13.	Best Low-Budget Film (Short)',
                    '14.	Best First Short Film',
                    '15.	Best Director (Short)',
                    '16.	Best VFX',
                    '17.	Best LGBTQ+',
                    '18.	Best Music Video',
                    '19.	Best Original Score',
                    '20.	Best Actress',
                    '21.	Best Actor',
                    '22.	Best Director (Feature)',
                    '23.	Best Cinematography',
                    '24.	Best Feature Film',
                    '25.	Best Low-Budget Film (Feature)',
                    '26.	Best Documentary Feature Film',
                    '27.	Best Student Film (Feature)',
                    '28.	Best First Feature Film',
                    '29.	Best Film Noir',
                    '30.	Best Editing',
                    '31.	Best Short Comedy',
                    '32.	Best Sound Design']; // Ваш список категорий

    const categoryGroups = [
        {
            title: "Film Production",
            icon: <VideoCameraOutlined />,
            color: "#2A5EE6"
        },
        {
            title: "Acting Awards",
            icon: <TeamOutlined />,
            color: "#2A5EE6"
        },
        {
            title: "Technical Awards",
            icon: <StarOutlined />,
            color: "#2A5EE6"
        },
        {
            title: "Special Awards",
            icon: <TrophyOutlined />,
            color: "#2A5EE6"
        }
    ];

    return (
        <div className={styles.wrapper} id={"category"}>
            <div className={styles.decorativeWaves}></div>
            <Container>
                <motion.div 
                    className={styles.inner}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8 }}
                >
                    <BlockInfoText 
                        title="CATEGORIES"
                        description="The Mosaic Film Festival has more than 30 different categories. Perhaps it is your film that can become the winner in one of them!"
                        titleColor="#2A5EE6"
                        align="center"
                    />

                    <div className={styles.categoryGroups}>
                        {categoryGroups.map((group, index) => (
                            <motion.div 
                                key={index}
                                className={styles.groupCard}
                                style={{ background: group.color }}
                                whileHover={{ scale: 1.05 }}
                                transition={{ type: "spring", stiffness: 300 }}
                            >
                                <div className={styles.groupIcon}>{group.icon}</div>
                                <h3 className={styles.groupTitle}>{group.title}</h3>
                            </motion.div>
                        ))}
                    </div>

                    <motion.div 
                        className={styles.buttonBlock}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <Button 
                            className={styles.button} 
                            type="primary" 
                            onClick={() => setIsModalOpen(true)}
                        >
                            Explore All Categories
                        </Button>
                    </motion.div>

                    <Modal
                        className={styles.modal}
                        onCancel={() => setIsModalOpen(false)}
                        footer={null}
                        title={<span className={styles.modalTitle}>Award Categories</span>}
                        open={isModalOpen}
                        width="80%"
                    >
                        <div className={styles.categoryGrid}>
                            {categories.map((category, index) => (
                                <div key={index} className={styles.categoryCard}>
                                    <div className={styles.categoryNumber}>{index + 1}</div>
                                    <div className={styles.categoryName}>{category}</div>
                                </div>
                            ))}
                        </div>
                    </Modal>
                </motion.div>
            </Container>
        </div>
    );
};

export default Category;