import React from 'react';
import styles from "./About.module.scss"
import Container from "../../UI/Container";
import BlockInfoText from "../../UI/BlockInfoText";
import { motion } from 'framer-motion';

const About = () => {
    const fadeIn = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 0.8 } }
    };

    return (
        <div className={styles.body} id={"about"}>
            <div className={styles.waveDecoration}></div>
            <Container>
                <div className={styles.bodyWrapper}>
                    <motion.div 
                        className={styles.textContainer}
                        initial="hidden"
                        animate="visible"
                        variants={fadeIn}
                    >
                        <BlockInfoText 
                            title={"ABOUT US"} 
                            description={"Mosaic Film Festival is dedicated to discovering true talents in the film industry who, for various reasons, have not yet become internationally renowned. The screening of the winners takes place in a unique location in the city, namely at Casa da Juventude da Tapada das Meroês, a youth center surrounded by greenery in the picturesque district of Tapada das Meroês. This unique center not only serves as a venue for film screenings but also serves as a source of inspiration for the youth. It contributes to the development of creative potential by providing young people with opportunities for self-expression, skill development, and participation in social activities."}
                            titleColor="#2A5EE6"
                        />
                    </motion.div>
                    
                    <motion.div 
                        className={styles.imageContainer}
                        whileHover={{ scale: 1.05 }}
                        transition={{ type: "spring", stiffness: 300 }}
                    >
                        <div className={styles.imageFrame}>
                            <img 
                                src="/images/Aboutt/logo.png" 
                                alt="Mosaic Film Festival" 
                                className={styles.logoImage}
                            />
                        </div>
                    </motion.div>
                </div>
            </Container>
        </div>
    );
};

export default About;