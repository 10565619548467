import React from 'react';
import styles from "./Footer.module.scss";
import Container from "../../UI/Container";
import { motion } from 'framer-motion';

const Footer = () => {
    return (
        <footer className={styles.wrapper}>
            <Container>
                <div className={styles.content}>
                    <motion.div 
                        className={styles.grid}
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        viewport={{ once: true }}
                    >
                        <div className={styles.logoSection}>
                            <img 
                                src="/images/Foot/logo.png" 
                                alt="Mosaic Film Festival Logo" 
                                className={styles.logo}
                            />
                            <p className={styles.tagline}>Celebrating Cinematic Diversity</p>
                        </div>

                        <nav className={styles.navColumns}>
                            <div className={styles.navGroup}>
                                <h4 className={styles.navTitle}>Navigation</h4>
                                <a href="#aboutUS" className={styles.navLink}>About Us</a>
                                <a href="#category" className={styles.navLink}>Categories</a>
                                <a href="#rules" className={styles.navLink}>Rules</a>
                                <a href="#awards" className={styles.navLink}>Awards</a>
                            </div>
                        </nav>

                        <div className={styles.ctaSection}>
                            <motion.a
                                href="https://filmfreeway.com/MosaicSintraFilmFestival"
                                className={styles.filmfreewayButton}
                                whileHover={{ scale: 1.05 }}
                                transition={{ type: "spring", stiffness: 300 }}
                            >
                                <img 
                                    src="/images/FFButtons/blue.png" 
                                    alt="Submit on FilmFreeway" 
                                />
                            </motion.a>
                        </div>
                    </motion.div>

                    <div className={styles.divider}></div>

                    <div className={styles.bottomSection}>
                        <p className={styles.copyright}>
                            © {new Date().getFullYear()} Mosaic Film Festival. All rights reserved.
                        </p>
                    </div>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;