import React from 'react';
import styles from "./Features.module.scss";
import { motion } from 'framer-motion';
import Container from "../../UI/Container";
import BlockInfoText from "../../UI/BlockInfoText";

const Features = () => {
    const imageVariants = {
        hidden: { opacity: 0, x: 50 }, // Изменили начальное положение
        visible: { 
            opacity: 1, 
            x: 0,
            transition: { 
                type: "spring", 
                stiffness: 120,
                damping: 20,
                delay: 0.3
            }
        }
    };

    return (
        <div className={styles.wrapper} id={"features"}>
            <div className={styles.waveDecoration}></div>
            <Container>
                <motion.div 
                    className={styles.container}
                    initial="hidden"
                    animate="visible"
                    transition={{ staggerChildren: 0.2 }}
                >
                    <motion.div 
                        className={styles.imageContainer}
                        variants={imageVariants}
                        whileHover={{ scale: 1.03 }}
                    >
                        <div className={styles.imageFrame}>
                            <img 
                                src="/images/Features/people.png" 
                                alt="Festival Features"
                                className={styles.featureImage}
                            />
                            <div className={styles.imageOverlay}>
                                <span className={styles.overlayText}>Creative Diversity</span>
                            </div>
                        </div>
                    </motion.div>

                    <BlockInfoText 
                        title={"FEATURES"} 
                        titleColor="#2A5EE6"
                        align="left" // Выравнивание текста вправо
                        description="The Mosaic Film Festival is a place where a knowledgeable jury evaluates not only the financial aspect of your film but, on the contrary, emphasizes the search and recognition of new authors with undeniable talent and taste. At our festival, you can explore a multitude of genres and styles, as well as witness the art of cinema in all its glory. We strive to support diversity and innovation in the world of cinematic arts, providing the audience with access to and experimental film genres. Our festival represents art that inspires, provokes thought, and supports the creative development of young filmmakers."
                    />
                </motion.div>
            </Container>
        </div>
    );
};

export default Features;