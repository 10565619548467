import React from 'react';
import styles from "./Rules.module.scss";
import { Collapse } from 'antd';
import Container from "../../UI/Container";
import BlockInfoText from "../../UI/BlockInfoText";
import { 
    GlobalOutlined,
    ClockCircleOutlined,
    CopyrightOutlined,
    VideoCameraOutlined,
    TrophyOutlined,
    CalendarOutlined,
    ToolOutlined,
    FileTextOutlined,
    SyncOutlined
} from '@ant-design/icons';

const { Panel } = Collapse;

const Rules = () => {
    const rulesList = [
        {
            icon: <GlobalOutlined />,
            title: "Language Requirements",
            content: "Presenting films in English or with English subtitles."
        },
        {
            icon: <ClockCircleOutlined />,
            title: "Film Duration",
            content: "Limiting the duration of short films to 40 minutes."
        },
        {
            icon: <CopyrightOutlined />,
            title: "Copyright",
            content: "Filmmakers are responsible for copyrights to their materials."
        },
        {
            icon: <VideoCameraOutlined />,
            title: "Screening Rights",
            content: "Organizers may screen your film before an audience and possibly online."
        },
        {
            icon: <TrophyOutlined />,
            title: "Selection Process",
            content: "Films can be nominated for an award in a specific category, but not all nominated films will be shown. The Official Selection determines which nominated films will be screened."
        },
        {
            icon: <CalendarOutlined />,
            title: "Event Changes",
            content: "Organizers reserve the right to change the date and venue of the festival under special circumstances."
        },
        {
            icon: <ToolOutlined />,
            title: "Technical Responsibility",
            content: "Technical issues with films are entirely the responsibility of the filmmakers, and malfunctions may result in rejection without refund."
        },
        {
            icon: <FileTextOutlined />,
            title: "Terms Agreement",
            content: "By submitting, you agree to our terms and conditions and consent to receive festival-related communications."
        },
        {
            icon: <SyncOutlined />,
            title: "Policy Updates",
            content: "Organizers reserve the right to update terms and conditions without prior notice."
        }
    ];

    return (
        <div className={styles.wrapper} id="rules">
            <Container>
                <div className={styles.content}>
                    <BlockInfoText
                        title="RULES & CONDITIONS"
                        description="Essential guidelines for participation and submissions"
                        titleColor="#2A5EE6"
                        align="center"
                    />
                    
                    <div className={styles.rulesList}>
                        <Collapse 
                            bordered={false}
                            ghost
                            expandIconPosition="end"
                        >
                            {rulesList.map((rule, index) => (
                                <Panel 
                                    key={index}
                                    header={
                                        <div className={styles.panelHeader}>
                                            <span className={styles.icon}>{rule.icon}</span>
                                            {rule.title}
                                        </div>
                                    }
                                    className={styles.panel}
                                >
                                    <div className={styles.panelContent}>{rule.content}</div>
                                </Panel>
                            ))}
                        </Collapse>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Rules;