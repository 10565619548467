import React from 'react';
import styles from "./Awards.module.scss";
import { motion } from 'framer-motion';
import Container from "../../UI/Container";
import BlockInfoText from "../../UI/BlockInfoText";

const Awards = () => {
    const imageVariants = {
        hidden: { opacity: 0, x: 50 },
        visible: { 
            opacity: 1, 
            x: 0,
            transition: { 
                type: "spring", 
                stiffness: 120,
                damping: 20,
                delay: 0.3
            }
        }
    };

    return (
        <div className={styles.wrapper} id={"awards"}>
            <div className={styles.decorativeWaves}></div>
            <Container>
                <motion.div 
                    className={styles.container}
                    initial="hidden"
                    animate="visible"
                    transition={{ staggerChildren: 0.2 }}
                >
                    <BlockInfoText 
                        title={"AWARDS"} 
                        titleColor="#2A5EE6"
                        align="left"
                        description="Our experts will carefully select films for the official selection from the pool of nominees. Films presented at the event will be chosen from the official selection and showcased on our website. The best of them will be honored with awards in various categories of our film festival."
                    />
                    
                    <motion.div 
                        className={styles.imageContainer}
                        variants={imageVariants}
                        whileHover={{ scale: 1.03 }}
                    >
                        <div className={styles.imageFrame}>
                            <img 
                                src="/images/Award/award.png" 
                                alt="Festival Awards"
                                className={styles.awardImage}
                            />
                            <div className={styles.imageOverlay}>
                                <span className={styles.overlayText}>Prestigious Awards</span>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            </Container>
        </div>
    );
};

export default Awards;