import React from 'react';
import styles from "./BlockInfoText.module.scss";
import { motion } from 'framer-motion';

export default function BlockInfoText({ 
  title, 
  description, 
  titleColor = '#2A5EE6', 
  align = 'left' 
}) {
  return (
    <motion.div 
      className={styles.wrapper}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      <div className={styles.text} style={{ textAlign: align }}>
        <div className={styles.title} style={{ color: titleColor }}>
          {title}
          <div className={styles.titleUnderline}></div>
        </div>
        <p className={styles.description}>
          {description}
        </p>
      </div>
    </motion.div>
  );
};