import React, { useState, useCallback, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiX, FiMenu, FiChevronDown, FiExternalLink } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import styles from './Header.module.scss';
import { useScrollDirection } from './useScrollDirection';
import { useLockBodyScroll } from './useLockBodyScroll';

const navigationItems = [
  { name: 'About Us', href: '#about' },
  { name: 'Submit', href: '#submit', external: true },
  { name: 'Categories', href: '#categories' },
  {
    name: 'Info',
    subItems: [
      { name: 'Awards and Prizes', href: '#awards' },
      { name: 'Rules and Conditions', href: '#rules' },
    ],
  },
];

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const scrollDirection = useScrollDirection();
  const location = useLocation();

  useLockBodyScroll(isMobileMenuOpen);

  const closeAllMenus = useCallback(() => {
    setIsMobileMenuOpen(false);
    setIsSubMenuOpen(false);
  }, []);

  useEffect(() => {
    closeAllMenus();
  }, [location, closeAllMenus]);

  return (
    <header
      className={clsx(styles.header, {
        [styles.hidden]: scrollDirection === 'down',
        [styles.scrolled]: scrollDirection !== 'top',
      })}
    >
      <div className={styles.container}>
        <nav className={styles.nav}>
          <Link to="/" className={styles.logo}>
            MOSAIC
            <span className={styles.logoSub}>Film Festival</span>
          </Link>

          {/* Desktop Navigation */}
          <div className={styles.desktopNav}>
            {navigationItems.map((item) => (
              <div key={item.name} className={styles.navItemWrapper}>
                {item.subItems ? (
                  <div
                    className={styles.navItemWithSubmenu}
                    onMouseEnter={() => setIsSubMenuOpen(true)}
                    onMouseLeave={() => setIsSubMenuOpen(false)}
                  >
                    <span className={styles.navItem}>
                      {item.name}
                      <FiChevronDown className={styles.chevron} />
                    </span>
                    
                    <AnimatePresence>
                      {isSubMenuOpen && (
                        <motion.div
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          className={styles.submenu}
                        >
                          {item.subItems.map((subItem) => (
                            <Link
                              key={subItem.name}
                              to={subItem.href}
                              className={styles.submenuItem}
                            >
                              {subItem.name}
                            </Link>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                ) : (
                <a href={item.href} className={styles.navItem}>
                {item.name}
                </a>
                )}
              </div>
            ))}
          </div>

          {/* Mobile Menu Button */}
          <button
            className={styles.mobileMenuButton}
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-label="Toggle navigation menu"
          >
            {isMobileMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </button>
        </nav>

        {/* Mobile Menu */}
<AnimatePresence>
  {isMobileMenuOpen && (
    <motion.div
      initial={{ x: '100%' }}
      animate={{ x: 0 }}
      exit={{ x: '100%' }}
      transition={{ type: 'tween', duration: 0.3 }}
      className={styles.mobileMenu}
    >
      <div className={styles.mobileMenuHeader}>
        <div className={styles.logo}>MOSAIC</div>
        <button
          className={styles.mobileMenuCloseButton}
          onClick={closeAllMenus}
        >
          <FiX size={28} />
        </button>
      </div>
      
      <div className={styles.mobileNavItems}>
        {navigationItems.map((item) => (
          <div key={item.name} className={styles.mobileNavItemWrapper}>
            {item.subItems ? (
              <>
                <button
                  className={styles.mobileNavItem}
                  onClick={() => setIsSubMenuOpen(!isSubMenuOpen)}
                >
                  {item.name}
                  <FiChevronDown
                    className={clsx(styles.submenuIndicator, {
                      [styles.rotated]: isSubMenuOpen,
                    })}
                    size={20}
                  />
                </button>
                
                <AnimatePresence>
                  {isSubMenuOpen && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      className={styles.mobileSubmenu}
                    >
                      {item.subItems.map((subItem) => (
                        <Link
                          key={subItem.name}
                          to={subItem.href}
                          className={styles.mobileSubmenuItem}
                          onClick={closeAllMenus}
                        >
                          {subItem.name}
                        </Link>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </>
            ) : (
              <Link
                to={item.href}
                className={styles.mobileNavItem}
                onClick={closeAllMenus}
                target={item.external ? '_blank' : undefined}
              >
                {item.name}
                {item.external && <FiExternalLink size={18} />}
              </Link>
            )}
          </div>
        ))}
      </div>
    </motion.div>
  )}
</AnimatePresence>
      </div>
    </header>
  );
};

export default Header;