import React from 'react';
import styles from "./Program.module.scss";
import { motion } from 'framer-motion';
import Container from "../../UI/Container";
import BlockInfoText from "../../UI/BlockInfoText";

const Program = () => {
    const imageVariants = {
        hidden: { opacity: 0, x: 50 },
        visible: { 
            opacity: 1, 
            x: 0,
            transition: { 
                type: "spring", 
                stiffness: 120,
                damping: 20,
                delay: 0.3
            }
        }
    };

    return (
        <div className={styles.wrapper} id={"history"}>
            <div className={styles.decorativeGrid}></div>
            <Container>
                <motion.div 
                    className={styles.container}
                    initial="hidden"
                    animate="visible"
                    transition={{ staggerChildren: 0.2 }}
                >
                                        <motion.div 
                        className={styles.imageContainer}
                        variants={imageVariants}
                        whileHover={{ scale: 1.03 }}
                    >
                        <div className={styles.imageWrapper}>
                            <img 
                                src="/images/Program/cinema.png" 
                                alt="Festival Program"
                                className={styles.cinemaImage}
                            />
                        </div>
                    </motion.div>
                    <BlockInfoText 
                        title={"THE PROGRAM"} 
                        titleColor="rgb(42, 94, 230)"
                        align="left"
                        description="The festival offers a rich spectrum of film screenings. In a small yet cozy auditorium, audiences can immerse themselves in the world of global cinema. The program includes full-length films, short films, avant-garde works, art-house cinema, festival pieces, and contemporary intellectual cinema. But this is not the full range of film screenings at our festival."
                    />
                    

                </motion.div>
            </Container>
        </div>
    );
};

export default Program;