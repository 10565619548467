import React, { useState, useEffect } from 'react';
import styles from './Presentation.module.scss';

const Presentation = () => {
    const [iframeHeight, setIframeHeight] = useState('80vh');

    useEffect(() => {
        const updateHeight = () => {
            const isMobile = window.innerWidth <= 768;
            setIframeHeight(isMobile ? '90vh' : '80vh');
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);
        return () => window.removeEventListener('resize', updateHeight);
    }, []);

    return (
        <div className={styles.wrapper}>
            <iframe 
                src="https://gamma.app/embed/ghuff2dwcis1gb5"
                className={styles.iframe}
                title="Mosaic Sintra Film Festival 2025 Official Award Recognition"
                allow="fullscreen"
                loading="lazy"
                style={{ height: iframeHeight }}
            />
        </div>
    );
};

export default Presentation;