import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import styles from './Banner.module.scss';

const Banner = () => {
    const [subtitleIndex, setSubtitleIndex] = useState(0);
    const subtitles = ['Celebrate Cinema', 'Discover Talent', 'Embrace Diversity'];

    useEffect(() => {
        const interval = setInterval(() => {
            setSubtitleIndex((prev) => (prev + 1) % subtitles.length);
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className={styles.banner}>
            <motion.div 
                className={styles.content}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
            >
                <h1 className={styles.title}>
                    MOSAIC
                    <span className={styles.filmFestival}>FILM FESTIVAL</span>
                </h1>
                
                <div className={styles.subtitle}>
                    {subtitles[subtitleIndex]}
                </div>

                <div className={styles.date}>
                    2025 | Sintra, Portugal
                </div>
            </motion.div>
        </div>
    );
};

export default Banner;